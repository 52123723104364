.image-with-text {
	display: grid;
	grid-gap: var(--space-32--px);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-gap: var(--space-16--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
		align-items: center;
	}
}

.image-with-text__text {
	margin: 0 auto;
	text-align: center;
	text-wrap: balance;
	max-width: 17.5rem;

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 27rem;
	}

	&:is([data-image-on-the-right] *) {
		/* up-to-small,max-width-767px */
		@media (width < 48rem) {
			grid-row: 1;
		}

		/* from-small,tablet-p,min-width-768px */
		@media (width >= 48rem) {
			grid-row: 1;
			grid-column: 1;
		}
	}
}

.image-with-text__image {
	margin: 0;

	&:is([data-image-on-the-right] *) {
		/* up-to-small,max-width-767px */
		@media (width < 48rem) {
			grid-row: 2;
		}

		/* from-small,tablet-p,min-width-768px */
		@media (width >= 48rem) {
			grid-row: 1;
			grid-column: 2;
		}
	}
}

.image-with-text__image__source {
	width: 100%;
}

.image-with-text__image__caption {
	color: var(--color-grey);
	margin-block-start: var(--space-16--rem);
}
