.newsletter-subscribe {
	background-color: var(--color-cream);
	background-image: url("../../images/texture-pattern-lightgrey.jpg");
	background-repeat: repeat;
	box-sizing: border-box;
	display: grid;
	width: 100%;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.newsletter-subscribe h2 {
	margin-block-end: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: var(--space-48--rem);
	}
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.newsletter-subscribe [data-column-a] {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.newsletter-subscribe [data-column-b] {
	aspect-ratio: 3 / 2;
	background-color: var(--color-brown);
}

.newsletter-subscribe__form-container {
	padding-inline: var(--space-16--px);
	padding-block: var(--space-32--rem) var(--space-48--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-block: 0;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline-start: var(--space-32--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline-start: var(--space-48--px);
	}
}

.newsletter-subscribe__form {
	border-block-end: 1px solid currentColor;
	display: flex;
	justify-content: space-between;
	align-items: center;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		max-width: 320px;
	}
}

.newsletter-subscribe__form input[type="email"] {
	appearance: none;
	background-color: transparent;
	border: none;
	box-sizing: border-box;
	color: currentColor;
	padding-inline: 0 var(--space-16--px);
	padding-block: 0.3125rem;
	width: 100%;
}

.newsletter-subscribe__form input[type="email"]::placeholder {
	color: var(--color-grey);
}

.newsletter-subscribe__form input[type="email"]:focus:not(:focus-visible) {
	outline: none;
}

.newsletter-subscribe__form .button {
	-mrh-resets: button;
}

.newsletter-subscribe__form .button .icon {
	display: block;
	padding-inline: var(--space-10--px);
	padding-block: var(--space-10--rem);
	width: 0.75rem;
	height: 0.625rem;
}

.newsletter-subscribe [data-column-b] img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
