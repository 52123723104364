* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	body {
		font-size: 1rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: var(--font-sans);
	margin-block: var(--space-24--rem);
}

:is(h1, h2, h3, h4, h5, h6, p):first-child {
	margin-block-start: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):last-child {
	margin-block-end: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):only-child {
	margin-block: 0;
}

/* #region Type-A */
.type-a,
p:not([class]),
ul:not([class]),
ol:not([class]),
td,
th {
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-style: normal;
	font-weight: 400;
	line-height: calc(20 / 13);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-a,
	p:not([class]),
	ul:not([class]),
	ol:not([class]),
	td,
	th {
		font-size: 0.9375rem;
		line-height: calc(22 / 15);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-a,
	p:not([class]),
	ul:not([class]),
	ol:not([class]),
	td,
	th {
		font-size: 1rem;
		line-height: calc(24 / 16);
	}
}
/* #endregion Type-A */

/* #region Type-B */
.type-b,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 400;
	line-height: calc(22 / 15);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-b,
	h4,
	h5,
	h6 {
		font-size: 1rem;
		line-height: calc(24 / 16);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-b,
	h4,
	h5,
	h6 {
		font-size: 1.125rem;
		line-height: calc(26 / 18);
	}
}
/* #endregion Type-B */

/* #region Type-C */
.type-c {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.16667; /* 116.667% */
	text-transform: uppercase;

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		font-size: 0.8125rem;
		line-height: 1.23077; /* 123.077% */
	}
}
/* #endregion Type-C */

/* #region Type-D */
.type-d {
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.69213; /* 169.231% */

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		font-size: 0.875rem;
		line-height: 1.71429; /* 171.429% */
	}
}
/* #endregion Type-D */

/* #region Type-E */
.type-e,
table caption {
	font-family: var(--font-sans);
	font-size: 0.6875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.27273; /* 127.273% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-e,
	table caption {
		font-size: 0.75rem;
		line-height: 1.33333; /* 133.333% */
	}
}
/* #endregion Type-E */

/* #region Type-F */
.type-f,
blockquote,
blockquote > p:not([class]),
h1,
h2 {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.33333; /* 133.333% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-f,
	blockquote,
	blockquote > p:not([class]),
	h1,
	h2 {
		font-size: 1.25rem;
		line-height: 1.3; /* 130% */
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-f,
	blockquote,
	blockquote > p:not([class]),
	h1,
	h2 {
		font-size: 1.375rem;
		line-height: 1.27273; /* 127.273% */
	}
}
/* #endregion Type-F */

/* #region Type-G */
.type-g,
h3 {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5; /* 150% */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-g,
	h3 {
		font-size: 1.125rem;
		line-height: 1.22222; /* 122.222% */
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-g,
	h3 {
		font-size: 1.25rem;
		line-height: 1.2; /* 120% */
	}
}
/* #endregion Type-G */

i,
em {
	font-style: italic;
}

blockquote {
	color: var(--color-grey);
}

blockquote p:first-of-type::before {
	content: "“";
}

blockquote p:last-of-type::after {
	content: "”";
}
