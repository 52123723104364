.video {
	color: var(--color-brown);
	position: relative;
	height: 100vh;
	height: 100svh;
	min-height: 37.5rem;
}

.video[data-video-is-dark] {
	color: var(--color-off-white);
}

.video__player {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.video[data-video-is-dark] .video__player::before {
	background-color: var(--color-black-20);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
}

.video__player__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.video__content {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 20rem;
	z-index: 3;
}

.video__content__logo {
	background-color: var(--color-brown);
	display: block;
	mask-image: url("../../../images/logo.svg");
	mask-size: cover;
	width: 8rem;
	height: 0.9375rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 9.5625rem;
		height: 1.125rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 12.75rem;
		height: 1.5rem;
	}
}

.video[data-video-is-dark] .video__content__logo {
	background-color: var(--color-off-white);
}

.video__content__title,
.video__content__description {
	margin-block: var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-32--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-48--rem);
	}
}
