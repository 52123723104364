.site-header {
	display: flex;
	padding-block: var(--space-16--rem);
	justify-content: space-between;
	align-items: center;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block: var(--space-24--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-32--rem);
	}
}

.site-header[data-is-off-white-header] {
	color: var(--color-off-white);
}

.site-header__logo {
	background-color: var(--color-brown);
	mask-image: url("../../../images/logo.svg");
	mask-size: cover;
	width: 7.96875rem;
	height: 0.9375rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 9.5625rem;
		height: 1.125rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 12.75rem;
		height: 1.5rem;
	}
}

.site-header__logo[data-is-on-homepage] {
	mask-image: url("../../../images/logo-emblem.svg");
}

.site-header[data-is-off-white-header] .site-header__logo {
	background-color: var(--color-off-white);
}

/* #region Mobile */
.site-header__mobile {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: none;
	}
}

.site-header__mobile__hamburger {
	-mrh-resets: button;
	width: 1rem;
	height: 0.75rem;
}

.site-header__mobile__hamburger .icon {
	stroke: var(--color-brown);
	width: 100%;
	height: 100%;
}

.site-header[data-is-off-white-header] .site-header__mobile__hamburger .icon {
	stroke: var(--color-off-white);
}
/* #endregion Mobile */

/* #region Desktop */
.site-header__desktop {
	/* up-to-small,max-width-767px */
	@media (width < 48rem) {
		display: none;
	}
}

.site-header__desktop__navigation__list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-header__desktop__navigation__list__item:not(:last-child) {
	margin-inline-end: var(--space-16--px);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-48--px);
	}
}
/* #endregion Desktop */
