:root {
	/* #region Colors */
	--color-foreground: rgb(97 60 46);
	--color-background: rgb(248 244 240);

	/* theme colors */
	--color-brown: rgb(97 60 46);
	--color-cream: rgb(237 234 230);
	--color-grey-light: rgb(200 195 190);
	--color-grey: rgb(115 115 115);
	--color-off-white: rgb(248 244 240);
	--color-black-20: rgb(0 0 0 / 0.2);
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Apercu", "Apercu Fallback", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-12--px: 12px;
	--space-12--rem: 0.75rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-24--px: 24px;
	--space-24--rem: 1.5rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-48--px: 48px;
	--space-48--rem: 3rem;
	--space-64--px: 64px;
	--space-64--rem: 4rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	--space-96--px: 96px;
	--space-96--rem: 6rem;
	/* #endregion Spacers */
	--site-header-height: 2.9375rem;

	/* #region  */
	--z-index-modal-dialog: 100;
	--z-index-header: 99;
	/* #endregion  */
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	:root {
		--site-header-height: 4.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	:root {
		--site-header-height: 5.5rem;
	}
}
