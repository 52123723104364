.row {
	margin-block: var(--space-32--rem);
	position: relative;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		margin-block: var(--space-48--rem);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-64--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-96--rem);
	}
}

.row[data-color="cream"] {
	background-color: var(--color-cream);
	background-image: url("../../images/texture-pattern-lightgrey.jpg");
	background-repeat: repeat;
	padding-block: var(--space-32--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-block: var(--space-48--rem);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block: var(--space-64--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-block: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-96--rem);
	}
}

.row[data-color="cream"] + .row[data-color="cream"] {
	margin-block-start: 0;
}

.row[data-color="cream"]:has(+ .row[data-color="cream"]) {
	margin-block-end: 0;
	padding-block-end: 0;
}

.row[data-color="cream"] + .row[data-color="cream"][data-gap-size-before="small"] {
	padding-block-start: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: var(--space-48--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-block-start: var(--space-64--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block-start: var(--space-96--rem);
	}
}
