.description-list {
	display: grid;
	grid-template-columns: 4fr 6fr;
	margin: 0;
	padding: 0;
}

.description-list dd,
.description-list dt {
	margin: 0;
	padding-inline: 0;
	padding-block: 7px;
	text-wrap: balance;

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-8--px);
	}
}

.description-list dd {
	padding-inline-start: 0.5rem;
	text-align: right;
}

.description-list dt {
	padding-inline-end: 0.5rem;
}

.description-list hr {
	background-color: transparent;
	border-bottom: 1px dashed currentColor;
	grid-column: span 2;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 0;
}
