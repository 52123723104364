.video-cta {
	-mrh-resets: button;
	color: currentColor;
	text-decoration: none;
	text-transform: uppercase;
	transition: opacity 96ms linear;
}

.video-cta:active {
	opacity: 0.6;
}

.video-cta__icon {
	box-sizing: border-box;
	display: block;
	margin-inline: auto;
	margin-block-end: var(--space-10--rem);
	width: 1.625rem;
	height: 1.625rem;
}

.video-cta__icon .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.video-cta__label {
	border-block-end-color: currentColor;
	border-block-end-style: solid;
	border-block-end-width: 1px;
	transition: border-block-end-color 96ms linear;
}

.video-cta:hover .video-cta__label {
	border-block-end-color: transparent;
}
