.places-grid {
	display: grid;
	grid-gap: 26px;
	margin: 0;
	padding: 0;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

.places-grid__item {
	display: block;
	list-style-type: none;
	padding-inline: 1.25rem;
	padding-block: 2.5rem;
	transition: background-color 96ms linear;
}

.places-grid__item:is(:hover, :active) {
	background-color: var(--color-off-white);
}

.places-grid__item__subtitle {
	color: var(--color-grey);
	margin-top: 0.3125rem;
}
