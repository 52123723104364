.navigation-overlay {
	background-color: var(--color-grey-light);
	width: 100%;
	height: 100%;
}

.navigation-overlay nav:focus {
	outline: none;
}

.navigation-overlay__header {
	display: flex;
	padding-block: var(--space-16--rem);
	justify-content: space-between;
	align-items: center;
}

.navigation-overlay__header .icon {
	width: 100%;
	height: 100%;
}

.navigation-overlay__header__logo {
	background-color: var(--color-brown);
	mask-image: url("../../../images/logo.svg");
	mask-size: cover;
	width: 7.96875rem;
	height: 0.9375rem;
}

.navigation-overlay__header__close__button {
	-mrh-resets: button;
	transform: translateY(0.125rem);
	width: 1rem;
	height: 1rem;
}

.navigation-overlay__menu {
	padding-block: var(--space-48--rem);
}

.navigation-overlay__menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__menu__list__item:not(:last-child) {
	margin-bottom: var(--space-16--rem);
}
