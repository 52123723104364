.press-grid {
}

.press-grid-list {
	display: grid;
	row-gap: var(--space-32--rem);
	column-gap: var(--space-32--px);
	grid-template-columns: 16.5rem;
	margin: 0;
	padding: 0;
	justify-content: space-around;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.press-grid-list {
		row-gap: var(--space-48--rem);
		column-gap: var(--space-48--px);
		grid-template-columns: repeat(2, 16.5rem);
		justify-content: space-between;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.press-grid-list {
		row-gap: var(--space-64--rem);
		column-gap: var(--space-64--px);
		grid-template-columns: repeat(3, 16.5rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.press-grid-list {
		row-gap: var(--space-96--rem);
		column-gap: var(--space-96--px);
		grid-template-columns: repeat(4, 16.5rem);
	}
}

.press-grid-list__item {
	display: block;
}

.press-grid-item {
	color: currentColor;
	text-decoration: none;
}

.press-grid-item__image-container {
	background-color: var(--color-brown);
	box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 0.3);
	margin: 20px;
	position: relative;
	transition: opacity 256ms;
	width: 14rem;
}

.press-grid-item__image {
	transition: opacity 256ms;
	width: 14rem;
	height: auto;
}

:any-link:hover .press-grid-item__image {
	opacity: 0.3;
}

.press-grid-item__image[data-fallback] {
	background-color: var(--color-cream);
	height: 19rem;
}

.press-grid-item__title-and-excerpt,
.press-grid-item__date {
	padding-block-start: var(--space-8--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.press-grid-item__title-and-excerpt,
	.press-grid-item__date {
		padding-block-start: var(--space-12--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.press-grid-item__title-and-excerpt,
	.press-grid-item__date {
		padding-block-start: var(--space-16--rem);
	}
}

.press-grid-item__title + .press-grid-item__excerpt {
	padding-block-start: var(--space-8--rem);
}

.press-grid-item__title,
.press-grid-item__excerpt,
.press-grid-item__date {
	text-wrap: balance;
}

.press-grid-item__title,
.press-grid-item__excerpt {
	color: var(--color-grey);
}

.press-grid-item__date {
	color: var(--color-brown);
}
