.l-default {
	position: relative;
}

.l-default__header[data-show-header-over-hero] {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: var(--z-index-header);
}
