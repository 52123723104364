@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	text-underline-position: from-font;
	min-height: 100vh;
	min-height: 100svh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: currentColor;
	border: none;
	height: var(--space-1--rem);
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid var(--color-grey-light);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-24--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

table caption {
	color: var(--color-grey);
	margin-block-start: var(--space-10--rem);
	text-align: left;
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-12--px);
	padding-block: var(--space-8--rem);
	text-align: left;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-15--px);
		padding-block: var(--space-10--rem);
	}
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0;
	margin-block-end: var(--space-24--rem);
	padding-inline-start: 1.25rem;
}

ul:not([class]) {
	list-style-type: disc;
}

ul:not([class]) li::marker {
	content: "•  ";
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
}

li:not([class]) {
	margin: 0;
	padding: 0;
	position: relative;
}

/* #region Default link styles */
a:not([class]),
.a-default {
	border-block-end-color: currentColor;
	color: currentColor;
	display: inline;
	text-decoration: underline 1px solid currentColor;
	transition:
		opacity 96ms linear,
		text-decoration-color 96ms linear,
		border-block-end-color 96ms linear;
}

a:not([class])[data-inverted-color],
.a-default[data-inverted-color] {
	border-block-end-color: transparent;
	text-decoration-color: transparent;
}

a:not([class])[data-decorate-with="border"],
.a-default[data-decorate-with="border"] {
	border-block-end-style: solid;
	border-block-end-width: 1px;
	text-decoration: none;
}

/* focus */
a:not([class]):focus,
.a-default:focus {
	border-block-end-color: transparent;
	text-decoration-color: transparent;
}

a:not([class])[data-inverted-color]:focus,
.a-default[data-inverted-color]:focus {
	border-block-end-color: currentColor;
	text-decoration-color: currentColor;
}

/* hover */
a:not([class]):hover,
.a-default:hover {
	border-block-end-color: transparent;
	text-decoration-color: transparent;
}

a:not([class])[data-inverted-color]:hover,
.a-default[data-inverted-color]:hover {
	border-block-end-color: currentColor;
	text-decoration-color: currentColor;
}

/* active */
a:not([class]):active,
.a-default:active {
	border-block-end-color: currentColor;
	opacity: 0.6;
	text-decoration-color: currentColor;
}

a:not([class])[data-inverted-color]:active,
.a-default[data-inverted-color]:active {
	border-block-end-color: currentColor;
	opacity: 0.6;
	text-decoration-color: currentColor;
}

/* is current */
a:not([class]):is([data-is-current], [data-is-current] *),
.a-default:is([data-is-current], [data-is-current] *) {
	border-block-end-color: currentColor;
	text-decoration-color: currentColor;
}

a:not([class])[data-inverted-color]:is([data-is-current], [data-is-current] *),
.a-default[data-inverted-color]:is([data-is-current], [data-is-current] *) {
	border-block-end-color: currentColor;
	text-decoration-color: currentColor;
}

p a:not([class]) {
	line-height: inherit;
}
/* #endregion Default link styles */

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

pre {
	white-space: pre-wrap;
}

blockquote {
	box-sizing: content-box;
	margin-inline: 0;
	margin-block: var(--space-24--rem);
	padding-inline: var(--space-48--px);
	padding-block: var(--space-24--rem);
	max-width: 25rem;
}

.mr-table-scroll-container {
	overflow-x: auto;
}
