.project-contact-and-information {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.project-contact-and-information [data-column-a],
.project-contact-and-information [data-column-b] {
	width: 100%;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		max-width: min(16.25rem, 50%);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		max-width: min(20rem, 50%);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: min(28rem, 50%);
	}
}

.project-contact-and-information__cta-group {
	margin-block-end: var(--space-32--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		&:last-child {
			margin-block-end: 0;
		}
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		max-width: 320px;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-48--rem);
	}
}

.project-contact-and-information__cta-group p {
	margin-block-end: var(--space-16--rem);
	text-wrap: balance;

	&:not(p:first-of-type) {
		margin-block-start: var(--space-16--rem);
	}
}
