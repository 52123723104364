.video-player-toggle {
	position: absolute;
	right: var(--space-16--px);
	bottom: var(--space-16--rem);
	z-index: 4;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		right: var(--space-32--px);
		bottom: var(--space-32--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		right: var(--space-48--px);
		bottom: var(--space-48--rem);
	}
}

.video-player-toggle__button {
	-mrh-resets: button;
}

.video-player-toggle__button:active {
	opacity: 0.6;
}

.video-player-toggle__button .icon {
	display: block;
	width: 0.5625rem;
	height: 0.6875rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 0.6875rem;
		height: 0.8125rem;
	}
}

.video-player-toggle .icon-pause {
	display: none;
}

.video-player-toggle[data-playing] .icon-pause {
	display: block;
}

.video-player-toggle[data-playing] .icon-play {
	display: none;
}
