.hero-project {
	background-color: var(--color-cream);
	background-image: url("../../../images/texture-pattern-lightgrey.jpg");
	background-repeat: repeat;
}

.hero-project__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-block-start: calc(var(--space-16--rem) + var(--site-header-height));
	padding-block-end: var(--space-16--rem);
	height: 100vh;
	height: 100svh;
	min-height: 37.5rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: calc(var(--space-32--rem) + var(--site-header-height));
		padding-block-end: var(--space-32--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		flex-direction: row;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block-start: calc(var(--space-48--rem) + var(--site-header-height));
		padding-block-end: var(--space-48--rem);
	}
}

.hero-project h1 {
	margin-block-end: var(--space-16--rem);
	text-transform: uppercase;
}

.hero-project p {
	margin-block-start: var(--space-16--rem);
}

.hero-project__media-container {
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		order: 2;
	}
}

.hero-project__media-container[data-video-is-dark] {
	color: var(--color-off-white);
}

.hero-project__media-container[data-video-is-dark] .hero-project__media::before {
	background-color: var(--color-black-20);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
}

.hero-project__media {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hero-project__media__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.hero-project__media__content {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 20rem;
	z-index: 3;
}

.hero-project__text {
	box-sizing: border-box;
	flex-grow: 0;
	flex-shrink: 0;

	/* up-to-medium,max-width-1023px */
	@media (width < 64rem) {
		margin-block-start: var(--space-16--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		display: flex;
		flex-direction: column;
		order: 1;
		padding-inline-end: var(--space-16--px);
		justify-content: space-between;
		width: 18.75rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 21rem;
	}
}
