.video-overlay {
	background-color: var(--color-off-white);
	position: relative;
	width: 100%;
	height: 100%;
}

.video-overlay__media {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-inline: var(--space-16--px);
	padding-block-start: 2.875rem;
	padding-block-end: var(--space-10--rem);
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
	max-height: 100%;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-32--px);
		padding-block-start: 3.375rem;
		padding-block-end: var(--space-16--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline: var(--space-48--px);
		padding-block-start: 4.5rem;
		padding-block-end: var(--space-32--rem);
	}
}

.video-overlay__media__player {
	aspect-ratio: 16 / 9;
	display: block;
	margin: 0 auto;
	position: relative;
	max-height: 100%;
	z-index: 2;
}

.video-overlay__media__source {
	opacity: 0;
	transition: opacity 128ms ease-in-out;
	visibility: hidden;
	width: 100%;
	height: auto;
}

.video-overlay__media__source[data-playback-started] {
	opacity: 1;
	visibility: visible;
}

/* #region Background + button */
.video-overlay__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.video-overlay__close-button {
	-mrh-resets: button;
	border-block-end-color: currentColor;
	display: block;
	font-size: inherit;
	line-height: inherit;
	position: fixed;
	text-transform: uppercase;
	top: var(--space-10--rem);
	right: var(--space-16--px);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		top: var(--space-16--rem);
		right: var(--space-32--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		top: var(--space-32--rem);
		right: var(--space-48--px);
	}
}
/* #endregion Background + button */
