/** Simple accordion pattern
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 */

.accordion {
	background-color: var(--color-off-white);
	margin: 0;
	padding: 0;
	width: 100%;
}

.accordion__item:not(:first-child) {
	border-block-start: 1px dashed var(--color-brown);
}

.accordion__heading {
	margin: 0;
	padding: 0;
}

.accordion__trigger {
	background: none;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-16--rem);
	position: relative;
	text-align: left;
	width: 100%;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-32--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline: var(--space-48--px);
	}
}

.accordion__trigger:focus,
.accordion__trigger:hover {
	cursor: pointer;
}

.accordion__title {
	display: flex;
	outline: none;
	justify-content: space-between;
	align-items: center;
	pointer-events: none;
}

.accordion__title__label {
	display: flex;
	align-items: center;
	text-transform: uppercase;
}

.accordion__title__icon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	margin-inline-end: var(--space-16--px);
	pointer-events: none;
	width: var(--space-16--rem);
	height: var(--space-16--rem);
}

:is(.accordion__title__read-more, .accordion__title__read-less) {
	text-transform: uppercase;
}

.accordion__trigger:is(:focus, :hover) :is(.accordion__title__read-more, .accordion__title__read-less) {
	border-block-end-color: transparent;
}

.accordion__trigger:active :is(.accordion__title__read-more, .accordion__title__read-less) {
	border-block-end-color: currentColor;
	opacity: 0.6;
}

.accordion__trigger[aria-expanded="true"] .accordion__title__read-more,
.accordion__trigger:not([aria-expanded="true"]) .accordion__title__read-less {
	display: none;
}

.accordion__panel {
	margin: 0 auto;
	padding-inline: var(--space-32--px);
	padding-block: 0 var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block: var(--space-16--rem) var(--space-32--rem);
		max-width: 25rem;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		max-width: 36.25rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 41.25rem;
	}
}

.accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion [data-our-values-icon="caring"] {
	background-image: url("../../images/value-caring.svg");
}

.accordion [data-our-values-icon="holistic"] {
	background-image: url("../../images/value-encompassing.svg");
}

.accordion [data-our-values-icon="authenticity"] {
	background-image: url("../../images/value-authenticity.svg");
}

.accordion [data-our-values-icon="transparency"] {
	background-image: url("../../images/value-transparency.svg");
}

.accordion [data-our-values-icon="sustainability"] {
	background-image: url("../../images/value-sustainability.svg");
}

.accordion [data-our-values-icon="beauty"] {
	background-image: url("../../images/value-beauty.svg");
}

.accordion [data-our-values-icon="nature"] {
	background-image: url("../../images/value-nature.svg");
}

.accordion [data-our-values-icon="stillness"] {
	background-image: url("../../images/value-stillness.svg");
}
