.site-footer {
	background-color: var(--color-background);
	padding-block-start: var(--space-32--rem);
	padding-block-end: var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: var(--space-48--rem);
		padding-block-end: var(--space-32--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block-start: var(--space-96--rem);
		padding-block-end: var(--space-48--rem);
	}
}

.site-footer__logo {
	display: flex;
	margin-block-end: var(--space-32--rem);
	align-items: center;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: var(--space-48--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-96--rem);
	}
}

.site-footer__logo__source {
	background-color: var(--color-brown);
	mask-image: url("../../../images/logo-emblem.svg");
	mask-size: cover;
	width: 7.96875rem;
	height: 0.9375rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 9.5625rem;
		height: 1.125rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 12.75rem;
		height: 1.5rem;
	}
}

.site-footer__logo__title {
	margin: 0;
	margin-inline-start: var(--space-8--px);
	text-transform: uppercase;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-inline-start: var(--space-16--px);
	}
}

/* #region social navigation */
.site-footer__social-navigation {
	margin-block-end: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: var(--space-48--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-96--rem);
	}
}

.site-footer__social-navigation__list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__social-navigation__list__item:not(:last-child) {
	margin-inline-end: var(--space-10--px);
}

.site-footer__social-navigation__list__item:not(:last-child) a::after {
	content: ",";
	content: "," / "";
}
/* #endregion social navigation */

.site-footer__bottom {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

/* #region primary navigation */
.site-footer__primary-navigation {
	margin-block-end: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: 0;
	}
}

.site-footer__primary-navigation__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__primary-navigation__list__item:not(:last-child) {
	margin-block-end: var(--space-16--px);
}
/* #endregion primary navigation */

/* #region legal navigation */
.site-footer__legal-navigation__list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__legal-navigation__list__item:not(:last-child) {
	margin-inline-end: var(--space-16--px);
}

.site-footer__legal-navigation__list__item {
	color: var(--color-grey);
}
/* #endregion legal navigation */
