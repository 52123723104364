.other-project {
}

.other-project__title {
	margin-block-end: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: var(--space-48--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block-end: var(--space-64--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-96--rem);
	}
}

.other-project .project-grid-item {
	display: block;
	margin: 0 auto;
	max-width: 18rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		max-width: 28.75rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 34.375rem;
	}

	/* from-xlarge,min-width-1680px */
	@media (width >= 105rem) {
		max-width: 40.625rem;
	}
}
