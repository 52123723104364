.wrapper {
	box-sizing: border-box;
	margin: 0 auto;
	padding-inline: var(--space-16--px);
	width: 100%;
	max-width: 105rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: var(--space-32--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline: var(--space-48--px);
	}
}

.wrapper[data-size="wide"] {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-inline: 0;
		max-width: 25rem;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		max-width: 36.25rem;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		max-width: 50rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 55.625rem;
	}
}

.wrapper[data-size="narrow"] {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-inline: 0;
		max-width: 25rem;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		max-width: 36.25rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-width: 41.25rem;
	}
}
