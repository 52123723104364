.image-50-50 {
	display: grid;
	grid-row-gap: var(--space-32--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50 {
		grid-column-gap: var(--space-16--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.image-50-50__media {
	margin: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50[data-only-image-b] .image-50-50__media {
		grid-column: 2;
	}
}

.image-50-50__media__image {
	width: 100%;
}

.image-50-50__media__caption {
	color: var(--color-grey);
	margin-block-start: var(--space-16--rem);
}
