.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	text-align: center;
	width: 100%;
	min-height: 100vh;
	min-height: 100svh;
}

.l-error__container {
	padding-inline: var(--space-16--px);
	width: 100%;
	max-width: 25rem;
}

.l-error__header h1 {
	margin-block: var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block: var(--space-32--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block: var(--space-48--rem);
	}
}

.l-error__header__logo {
	display: block;
	margin: 0 auto;
	width: 7.96875rem;
	height: 0.9375rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 9.5625rem;
		height: 1.125rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 12.75rem;
		height: 1.5rem;
	}
}

.l-error__header__logo svg {
	fill: var(--color-brown);
	width: 100%;
}
