.project-grid-item {
	text-decoration: none;
	transition: opacity 96ms ease-in-out;
}

.project-grid-item:active {
	opacity: 0.6;
}

.project-grid-item__inner {
	position: relative;
}

.project-grid-item__title {
	color: var(--color-brown);
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 320ms ease-in-out;
	width: 100%;
}

.project-grid-item__title[data-is-off-white-text] {
	color: var(--color-cream);
}

.project-grid-item:hover .project-grid-item__title {
	opacity: 0;
}

/* #region Image */
.project-grid-item__images {
	display: grid;
}

.project-grid-item__image {
	grid-row: 1;
	grid-column: 1;
	transition: opacity 320ms ease-in-out;
}

.project-grid-item[data-has-second-thumbnail]:hover .project-grid-item__image[data-featured-image] {
	opacity: 0;
}

.project-grid-item__image-fallback {
	aspect-ratio: 3 / 2;
	background-color: var(--color-brown);
	width: 100%;
}

.project-grid-item__image__source {
	width: 100%;
}
/* #endregion Image */

/* #region Content */
.project-grid-item__content {
	color: var(--color-grey);
	display: flex;
	margin-block-start: var(--space-10--px);
	justify-content: space-between;
	align-items: flex-start;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: var(--space-16--px);
	}
}

.project-grid-item__excerpt {
	margin-inline-end: var(--space-32--px);
}

.project-grid-item__content__tag {
	display: inline-block;
	flex-shrink: 0;
	margin-inline-start: auto;
	padding-block: 0.25rem;
	text-transform: uppercase;
}

.project-grid-item__content__tag[data-for-sale-tag] {
	background-color: var(--color-cream);
	padding-inline: var(--space-8--px);
}
/* #endregion Content */
