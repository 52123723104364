.collaboration-grid {
	display: grid;
	grid-row-gap: var(--space-32--px);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		grid-template-columns: repeat(2, 13.75rem);
		justify-content: space-between;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-row-gap: var(--space-48--rem);
		grid-template-columns: repeat(2, 18rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-row-gap: var(--space-64--rem);
		grid-template-columns: repeat(3, 18rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-row-gap: var(--space-64--rem);
		grid-template-columns: repeat(3, 22.125rem);
	}

	/* from-xlarge,min-width-1680px */
	@media (width >= 105rem) {
		grid-template-columns: repeat(3, 26.25rem);
	}
}

.collaboration-grid__item {
	text-decoration: none;
	transition: opacity 96ms ease-in-out;

	/* up-to-xsmall,max-width-567px */
	@media (width < 35.5rem) {
		margin: 0 auto;
		width: 100%;
		max-width: 13.75rem;
	}
}

.collaboration-grid__item:active {
	opacity: 0.6;
}

.collaboration-grid__item__images {
	display: grid;
}

.collaboration-grid__item__image {
	grid-row: 1;
	grid-column: 1;
	transition: opacity 320ms ease-in-out;
}

.collaboration-grid__item[data-has-second-thumbnail]:hover .collaboration-grid__item__image[data-featured-image] {
	opacity: 0;
}

.collaboration-grid__item__image-fallback {
	aspect-ratio: 2 / 3;
	background-color: var(--color-brown);
	width: 100%;
}

.collaboration-grid__item__image__source {
	width: 100%;
}

.collaboration-grid__item__title {
	color: var(--color-grey);
	margin-block-start: var(--space-10--rem);
	text-transform: uppercase;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: var(--space-16--rem);
	}
}
