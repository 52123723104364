.projects-grid {
	display: grid;
	grid-row-gap: var(--space-48--rem);
	justify-content: center;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-template-columns: repeat(2, 28.75rem);
		justify-content: space-between;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-row-gap: var(--space-96--rem);
		grid-template-columns: repeat(2, 34.375rem);
	}

	/* from-xlarge,min-width-1680px */
	@media (width >= 105rem) {
		grid-template-columns: repeat(2, 40.625rem);
	}
}

.projects-grid .project-grid-item {
	/* up-to-small,max-width-767px */
	@media (width < 48rem) {
		max-width: 18rem;
	}

	/* from-small and up-to-medium,max-width-1023px */
	@media (width >= 48rem) and (width < 64rem) {
		max-width: 28.75rem;
	}
}
