.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-icons-in-sentence::after,
.u-icons-in-sentence__first-word::after {
	background-color: currentColor;
	content: "";
	display: inline-block;
	margin-inline: 5px 2px;
	margin-inline: 0.6ch 0.25ch;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	height: 0.6em;
	height: 1.2ch;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.u-icons-in-sentence::after,
	.u-icons-in-sentence__first-word::after {
		margin-inline: 6px 3px;
		margin-inline: 0.565ch 0.275ch;
	}
}

.u-icons-in-sentence::after {
	mask-image: url("../../images/orb-two-three.svg");
	width: 1.25em;
	width: 2.5ch;
}

.u-icons-in-sentence__first-word::after {
	mask-image: url("../../images/orb-one.svg");
	width: 0.5em;
	width: 1ch;
}

/* #region Text Indent */
[data-u-text-indent]:is(h2, h3, h4, h5, h6, p) {
	text-indent: clamp(var(--space-16--px), 1rem, var(--space-96--px));
}

[data-u-text-indent]:is(h2, h3, h4, h5, h6, p) > * {
	text-indent: initial;
}
/* #endregion Text Indent */
