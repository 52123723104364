.image {
	margin: 0;
}

.image__source {
	width: 100%;
}

.image__caption {
	color: var(--color-grey);
	margin-block-start: var(--space-16--rem);
}
