.text-columns {
	display: grid;
	grid-gap: var(--space-16--px);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-gap: var(--space-32--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-gap: var(--space-48--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-columns [data-column-b] {
		grid-column: 2;
	}
}
