.carousel {
	--vertical-gap: var(--space-10--rem);
	--block-padding: 0;
	--inline-padding: 0;
}

.carousel[data-shown-fullscreen] {
	--block-padding: var(--space-10--px);
	--inline-padding: var(--space-16--px);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.carousel[data-shown-fullscreen] {
		--block-padding: var(--space-16--px);
		--inline-padding: var(--space-32--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.carousel[data-shown-fullscreen] {
		--block-padding: var(--space-32--px);
		--inline-padding: var(--space-48--px);
	}
}

.carousel {
	box-sizing: border-box;
	color: var(--color-brown);
	display: grid;
	grid-template-rows: max-content minmax(0, 1fr);
	padding-inline: var(--inline-padding);
	padding-block: var(--block-padding);
	width: 100%;
	height: 100%;
}

.carousel::backdrop {
	/* `::backdrop` does not inherit props from `:root`, can't use `var()` here. */
	background: rgb(248 244 240);
}

.carousel[data-shown-fullscreen] [data-horizontal-spacer="a"] {
	height: 1.625rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		height: 1.75rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		height: 1.875rem;
	}
}

.carousel[data-shown-fullscreen] [data-horizontal-spacer="b"] {
	max-height: calc(100vh - ((var(--vertical-gap) * 2) + (var(--block-padding) * 2) + 1.625rem + 1.375rem));

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		max-height: calc(100vh - ((var(--vertical-gap) * 2) + (var(--block-padding) * 2) + 1.75rem + 1.375rem));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		max-height: calc(100vh - ((var(--vertical-gap) * 2) + (var(--block-padding) * 2) + 1.875rem + 1.5rem));
	}
}

.carousel[data-shown-fullscreen] [data-horizontal-spacer="c"] {
	height: 1.375rem;

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		height: 1.5rem;
	}
}

.carousel__items {
	display: grid;
	margin: 0;
	padding: 0;
	height: max-content;
}

.carousel[data-shown-fullscreen] .carousel__items {
	height: 100%;
}

.carousel__item {
	display: block;
	grid-row: 1;
	grid-column: 1;
	opacity: 0;
	transition: opacity 256ms ease-in-out;
	width: 100%;
	z-index: 0;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
	z-index: 1;
}

.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	pointer-events: none;
	z-index: 2;
}

.carousel__item[data-carousel-with-caption] {
	padding-block-end: 0;
}

.carousel__item__next-button {
	-mrh-resets: button;
	display: block;
	width: 100%;
	height: 100%;
}

.carousel__figure {
	margin: 0;
	pointer-events: none;
}

.carousel[data-shown-fullscreen] .carousel__figure {
	display: grid;
	grid-template-rows: minmax(0, 1fr) max-content;
	height: 100%;
}

.carousel__image_container {
	aspect-ratio: 3 / 2;
	width: 100%;
}

.carousel[data-shown-fullscreen] .carousel__image_container {
	aspect-ratio: auto;
}

.carousel__image {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.carousel[data-shown-fullscreen] .carousel__image {
	margin: 0 auto;
	width: auto;
	height: 100%;
}

.carousel__image__caption {
	color: var(--color-grey);
	margin-block-start: var(--vertical-gap);
}

.carousel[data-shown-fullscreen] .carousel__image__caption {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.carousel__controls {
	display: flex;
	margin-block-end: var(--vertical-gap);
	justify-content: space-between;
	align-items: center;
}

.carousel__buttons-and-counter {
	display: flex;
	align-items: center;
}

.carousel__buttons {
	display: flex;
	margin-inline-end: var(--space-16--px);
}

.carousel__button {
	-mrh-resets: button;
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel__button .icon {
	box-sizing: border-box;
	fill: none;
	padding: 0.4375rem;
	pointer-events: none;
	stroke: currentColor;
	width: 1.3125rem;
	height: 1.625rem;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 1.375rem;
		height: 1.75rem;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 1.4375rem;
		height: 1.875rem;
	}
}

.carousel__button[data-carousel-previous] .icon {
	transform: rotate(180deg);
}

.carousel__counter {
	font-variant-numeric: tabular-nums;
}

.carousel__fullscreen-button {
	-mrh-resets: button;
	border-block-end-color: currentColor;
	font-size: inherit;
	line-height: inherit;
	text-transform: uppercase;
}

.carousel:not(:defined) .carousel__fullscreen-button,
[data-no-fullscreen-support] .carousel__fullscreen-button {
	display: none;
}
