.hero-collaboration {
	background-color: var(--color-cream);
	background-image: url("../../../images/texture-pattern-lightgrey.jpg");
	background-repeat: repeat;
	box-sizing: border-box;
	padding-block-start: calc(var(--space-16--rem) + var(--site-header-height));
	padding-block-end: var(--space-16--rem);
	min-height: 37.5rem;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		height: 100vh;
		height: 100svh;
	}

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) and (height >= 450px) {
		min-height: auto;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-block-start: calc(var(--space-32--rem) + var(--site-header-height));
		padding-block-end: var(--space-32--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block-start: calc(var(--space-48--rem) + var(--site-header-height));
		padding-block-end: var(--space-48--rem);
	}
}

.hero-collaboration__inner {
	height: 100%;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		display: flex;
		flex-direction: row-reverse;
		gap: var(--space-16--px);
		justify-content: space-between;
	}
}

.hero-collaboration__media {
	flex-grow: 1;
	flex-shrink: 1;
}

.hero-collaboration__media__image {
	width: 100%;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.hero-collaboration__media__image {
		object-fit: contain;
		object-position: bottom right;
		height: 100%;
	}
}

.hero-collaboration__content {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	margin-inline-end: auto;
	justify-content: space-between;

	/* up-to-xsmall,max-width-559px */
	@media (width < 35rem) {
		margin-block-start: var(--space-16--rem);
	}

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		width: 200px;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: 300px;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		width: 320px;
	}
}

.hero-collaboration__content__title {
	margin: 0;
	text-transform: uppercase;

	/* up-to-xsmall,max-width-559px */
	@media (width < 35rem) {
		margin-block-end: var(--space-16--rem);
	}
}

.hero-collaboration__content__text {
	margin: 0;
}
