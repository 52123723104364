.filter {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-12--px);
	column-gap: var(--space-24--px);
	list-style-type: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	text-transform: uppercase;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		justify-content: flex-start;
	}
}
