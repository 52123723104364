.related-projects {
}

.related-projects__title {
	margin-block-end: var(--space-32--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: var(--space-48--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block-end: var(--space-64--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-96--rem);
	}
}

.related-projects__items {
	display: grid;
	grid-row-gap: var(--space-32--px);
	justify-content: center;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-row-gap: var(--space-24--px);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-column-gap: var(--space-24--px);
		grid-template-columns: repeat(2, 24.1875rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-template-columns: repeat(2, 27rem);
	}

	/* from-xlarge,min-width-1680px */
	@media (width >= 105rem) {
		grid-template-columns: repeat(2, 33.125rem);
	}
}

.related-projects__items .project-grid-item {
	/* up-to-small,max-width-767px */
	@media (width < 48rem) {
		max-width: 18rem;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) and (width < 64rem) {
		max-width: 24.1875rem;
	}
}
