.hero-image {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100vh;
	height: 100svh;
	min-height: 37.5rem;
}

.hero-image__inner {
	position: relative;
	z-index: 2;
}

.hero-image__inner[data-has-icons] {
	display: grid;
	grid-gap: var(--space-32--px);
	grid-template-rows: auto minmax(0, 1fr);
	justify-content: center;
	align-items: center;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		grid-gap: var(--space-48--rem);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		grid-gap: var(--space-64--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		grid-gap: var(--space-80--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		grid-gap: var(--space-96--rem);
	}
}

.hero-image__overlay {
	background-color: var(--color-black-20);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.hero-image__text {
	color: var(--color-brown);
	margin: 0;
	padding-inline: var(--space-16--rem);
	text-align: center;
	max-width: 20rem;
}

.hero-image__text[data-is-off-white-text] {
	color: var(--color-off-white);
}

@media (height < 40rem) {
	.hero-image__text[data-limit-number-of-lines] {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.hero-image__media {
	background-color: var(--color-black-20);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.hero-image__media__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* #region Icons */
.hero-image__icons-wrapper {
	display: flex;
	flex-direction: row;
	height: inherit;
}

.hero-image__icons-wrapper::before,
.hero-image__icons-wrapper::after {
	content: "";
	display: block;
	flex-grow: 1;
	flex-shrink: 1;
}

.hero-image__icons {
	display: flex;
	flex-grow: 1;
	flex-shrink: 2;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 32px;
	align-items: center;
	height: inherit;
}

.hero-image__icon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	width: 1.25rem;
	height: 1.25rem;
}

.hero-image__icon[data-hero-icon="caring"] {
	background-image: url("../../../images/value-caring.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="caring"] {
	background-image: url("../../../images/value-caring-white.svg");
}

.hero-image__icon[data-hero-icon="holistic"] {
	background-image: url("../../../images/value-encompassing.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="holistic"] {
	background-image: url("../../../images/value-encompassing-white.svg");
}

.hero-image__icon[data-hero-icon="authenticity"] {
	background-image: url("../../../images/value-authenticity.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="authenticity"] {
	background-image: url("../../../images/value-authenticity-white.svg");
}

.hero-image__icon[data-hero-icon="transparency"] {
	background-image: url("../../../images/value-transparency.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="transparency"] {
	background-image: url("../../../images/value-transparency-white.svg");
}

.hero-image__icon[data-hero-icon="sustainability"] {
	background-image: url("../../../images/value-sustainability.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="sustainability"] {
	background-image: url("../../../images/value-sustainability-white.svg");
}

.hero-image__icon[data-hero-icon="beauty"] {
	background-image: url("../../../images/value-beauty.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="beauty"] {
	background-image: url("../../../images/value-beauty-white.svg");
}

.hero-image__icon[data-hero-icon="nature"] {
	background-image: url("../../../images/value-nature.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="nature"] {
	background-image: url("../../../images/value-nature-white.svg");
}

.hero-image__icon[data-hero-icon="stillness"] {
	background-image: url("../../../images/value-stillness.svg");
}

[data-is-off-white-text] .hero-image__icon[data-hero-icon="stillness"] {
	background-image: url("../../../images/value-stillness-white.svg");
}
/* #endregion Icons */
