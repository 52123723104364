.modal-dialog {
	display: none;
	opacity: 0;
	pointer-events: none;
	position: relative;
	z-index: var(--z-index-modal-dialog);
}

.modal-dialog[data-state="opening"],
.modal-dialog[data-state="open"],
.modal-dialog[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.modal-dialog:focus {
	outline: none;
}

.modal-dialog__content {
	display: none;
	opacity: 0;
}

.modal-dialog[data-state="opening"] .modal-dialog__content,
.modal-dialog[data-state="open"] .modal-dialog__content,
.modal-dialog[data-state="closing"] .modal-dialog__content {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
}

.modal-dialog[data-state="open"] .modal-dialog__content {
	display: block;
	opacity: 1;
}

/* #region Styleguide */
.l-styleguide ~ .modal-dialog {
	position: relative;
	z-index: 99999;
}
/* #endregion Styleguide */
